//css
import styles from './ContractCard.module.css';

//icons
import { AiOutlineArrowRight } from 'react-icons/ai';
import { CgFileDocument } from 'react-icons/cg';

// react router dom
import { useNavigate } from 'react-router-dom';

//interfaces
import { IContract } from '../../../interfaces/IContract';

//utils
import { PossiblesStatus } from '../../../utils/PossiblesStatus';

type Props = {
  contract: IContract
}
const ContractCard: React.FC<Props> = ({
                                         contract,
                                       }) => {

  const navigate = useNavigate();
  return (
    <div className={styles.contractCard}>
      <p
        className={`${styles[contract.status]}  ${styles.status}`}>{PossiblesStatus.statusContract(contract.status)}</p>
      <h2>{contract.bem}</h2>
      <div className={styles.cardFooter}>
        <div className={styles.contractInfo}>
          <p>Contrato</p>
          <div><CgFileDocument />{contract.contractCode}</div>
        </div>
        <button onClick={() => navigate(`/contract`, { state: { contract: contract } })}><AiOutlineArrowRight />
        </button>
      </div>
    </div>
  );
};

export default ContractCard;